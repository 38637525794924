import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';

import MediaEnquiry from './_media-enquiry';
import Layout from '../../components/layout';
import MediaSection from '../../components/Media/MediaSection';
import StickyNavigationBranded from '../../components/StickyNavigation/StickyNavigationBranded';
import PageData from './data/news-room.json';
import heroImage from '../../images/hero/newsroom.webp';

import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

const NewsRoomPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <title>{PageData.meta.title}</title>
          <meta name="description" content={PageData.meta.description} />
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/about-us/news-room/"
          />
        </Helmet>

        <div css="position:relative;">
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              pageImage={heroImage}
              title="News Room"
              subTitle={
                <>
                  Keep up to date with Latitude’s latest news and information,
                  media releases and articles.
                </>
              }
            />
          )}
        </div>
        <StickyNavigationBranded
          items={PageData.stickyNavigation}
          offsetElem="[data-sticky-navigation-offset]"
          trackId="news-room-stickynav"
        />
        <MediaSection />
        <MediaEnquiry />
      </main>
    </Layout>
  );
};

export default NewsRoomPage;
